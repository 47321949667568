import React, { createContext, useReducer } from 'react';
import axios from 'axios';
import { api } from '../common/common';

// import useLocalStorage from '../common/useLocalStorage';

const initialState = {
    user: null,
    loading: false,
    loaded: false,
    errors: {},
    message: null,
    color: null
}

export const UserStateContext = createContext();
export const UserDispatchContext = createContext();

const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'REQUEST_USER':
            return {
                ...state,
                loading: true,
                loaded: false,
                user: null,
                errors: {},
                message: null,
                color: null,
            };
        case 'GET_USERS_SUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: payload.user,
                errors: {},
                message: null,
                color: 'success',
            };
        case 'ADD_USER_SUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: payload.user,
                errors: {},
                message: 'Account created successfully, Kindly alert the admin to activate your account',
                color: 'success',
            };
            case 'ADD_USER_UNSUCCESSFULL':
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    user: null,
                    errors: payload.errors,
                    message: payload.message,
                    color: 'danger',
                };
            case 'DELETE_USER_SUCCESSFULL':
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    user: payload.user,
                    errors: {},
                    message: payload.message,
                    color: 'success',
                };
            case 'DELETE_USER_UNSUCCESSFULL':
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    user: state.user,
                    errors: payload.errors,
                    message: payload.message,
                    color: 'danger',
                };
        case 'UPDATE_USER_SUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: [payload.user] || state.user,
                errors: {},
                message: payload.message,
                color: 'success',
            };
            case 'UPDATE_USER_UNSUCCESSFULL':
                return {
                    ...state,
                    loading: false,
                    loaded: true,
                    user: null,
                    errors: payload.errors,
                    message: payload.message,
                    color: 'danger',
                };
            case 'GET_USERS_UNSUCCESSFULL':
                return {
                    ...state,
                loading: false,
                loaded: true,
                user: null,
                errors: {},
                message: 'Error getting users',
                color: 'danger',
            };
        case 'GET_USER_UNSUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: null,
                errors: payload.errors,
                message: payload.message,
                color: 'danger',
            };
        case 'LOGOUT':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: null,
                isLoggedIn: false,
                errors: {},
                message: null,
                color: null,
            };

        default:
            throw new Error('Unknown action type');
    }
}

const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, initialState);
    return (
        <UserDispatchContext.Provider value={dispatch}>
            <UserStateContext.Provider value={state}>
                {children}
            </UserStateContext.Provider>
        </UserDispatchContext.Provider>
    )
}


export const getUser = async (dispatch) => {
    // console.log(token);
    // let token = JSON.parse(localStorage.getItem('kadick'));
    dispatch({ type: 'REQUEST_USER' });
    await axios.get(`${api}users/`)
        .then(res => {
                // console.log(res)
                if (res.statusText === 'OK') {
                return dispatch({
                    type: 'GET_USERS_SUCCESSFULL',
                    payload: {
                        user: res.data
                    }
                });
            }
            else{
                return dispatch({
                type: 'GET_USERS_UNSUCCESSFULL'
            });}
        })
        .catch(err => {
            console.log(err);
            return dispatch({
                type: 'GET_USERS_UNSUCCESSFULL'
            });
        });
}

export const logout = async (dispatch) => {
    dispatch({ type: 'REQUEST_LOGIN' });
    // console.log('i did');
    localStorage.setItem('kadick', JSON.stringify(null));
    dispatch({ type: 'LOGOUT' });
}

export const addUser = async (dispatch, data) => {
    // dispatch({ type: 'REQUEST_USER' });
    console.log(data);
    try {
        await axios.post(`${api}users/admin`, data)
    .then(res => {
            console.log(res.data);
            if (!res.data.success) {
                console.log(res.data.message);
                return dispatch({
                    type: 'ADD_USER_UNSUCCESSFULL',
                    payload: {
                        errors: res.data.errors,
                        message: res.data.message,
                    }
                })
            }
            console.log(res.data.message);

            return dispatch({
                type: 'ADD_USER_SUCCESSFULL',
                payload: {
                    user: res.data.user,
                    errors: res.data.errors,
                    message: res.data.message,
                }
            })
        })
        .catch(err => {
            if (!err.response) {
                console.log(err.message);

                return dispatch({
                    type: 'ADD_USER_UNSUCCESSFULL',
                    payload: {
                        errors: {},
                        message: err.message,
                    }
                })
            }
            else {
                console.log(err.response.data.message);

                return dispatch({
                    type: 'ADD_USER_UNSUCCESSFULL',
                    payload: {
                        errors: err.response.data.errors,
                        message: err.response.data.message,
                    }
                })
            };
        })
    } catch (error) {
        console.log(error);
        return dispatch({
            type: 'ADD_USER_UNSUCCESSFULL',
            payload: {
                errors: {},
                message: error.message,
            }
        })
    }
}

export const updateUser = async (dispatch, data) => {
    dispatch({ type: 'REQUEST_USER' });
    console.log(data);
    try {
        await axios.put(`${api}users/${data._id}`, data)
    .then(res => {
            console.log(res.data);
            if (res.data.success === false) {
                console.log(res.data.message);
                return dispatch({
                    type: 'UPDATE_USER_UNSUCCESSFULL',
                    payload: {
                        // errors: res.data.errors,
                        errors: {},
                        message: res.data.message,
                    }
                })
            }
            console.log(res.data);

            return dispatch({
                type: 'UPDATE_USER_SUCCESSFULL',
                payload: {
                    user: res.data,
                    // errors: res.data.errors,
                    errors: {},
                    message: 'User updated successfully',
                }
            })
        })
        .catch(err => {
            if (!err.response) {
                console.log(err.message);

                return dispatch({
                    type: 'UPDATE_USER_UNSUCCESSFULL',
                    payload: {
                        errors: {},
                        message: err.message,
                    }
                })
            }
        })
    } catch (error) {
        console.log(error);
        return dispatch({
            type: 'UPDATE_USER_UNSUCCESSFULL',
            payload: {
                errors: {},
                message: error.message,
            }
        })
    }
}

export const deleteUser = async (dispatch, data) => {
    dispatch({ type: 'REQUEST_USER' });
    console.log(data);
    try {
        await axios.delete(`${api}users/${data._id}`, {id: data._id})
    .then(res => {
            console.log(res.data);
            if (res.data.success === false) {
                console.log(res.data.message);
                return dispatch({
                    type: 'DELETE_USER_UNSUCCESSFULL',
                    payload: {
                        // errors: res.data.errors,
                        errors: {},
                        message: res.data.message,
                    }
                })
            }
            console.log(res.data);

            return dispatch({
                type: 'DELETE_USER_SUCCESSFULL',
                payload: {
                    user: [res.data.user],
                    // errors: res.data.errors,
                    errors: {},
                    message: 'User deleted successfully',
                }
            })
        })
        .catch(err => {
            if (!err.response) {
                console.log(err.message);

                return dispatch({
                    type: 'DELETE_USER_UNSUCCESSFULL',
                    payload: {
                        errors: {},
                        message: err.message,
                    }
                })
            }
        })
    } catch (error) {
        console.log(error);
        return dispatch({
            type: 'DELETE_USER_UNSUCCESSFULL',
            payload: {
                errors: {},
                message: error.message,
            }
        })
    }
}

export const changeUserPassword = async (dispatch, data) => {
    // dispatch({ type: 'REQUEST_USER' });
    console.log(data);
    try {
        await axios.put(`${api}users/change/password/${data.userId}`, data)
    .then(res => {
            console.log(res.data);
            if (res.data.success === false) {
                console.log(res.data.message);
                return dispatch({
                    type: 'UPDATE_USER_UNSUCCESSFULL',
                    payload: {
                        // errors: res.data.errors,
                        errors: {},
                        message: res.data.message,
                    }
                })
            }
            console.log(res.data);

            return dispatch({
                type: 'UPDATE_USER_SUCCESSFULL',
                payload: {
                    user: [],
                    // errors: res.data.errors,
                    errors: {},
                    message: res.data.message,
                }
            })
        })
        .catch(err => {
            if (!err.response) {
                console.log(err.message);

                return dispatch({
                    type: 'UPDATE_USER_UNSUCCESSFULL',
                    payload: {
                        errors: {},
                        message: err.message,
                    }
                })
            }
        })
    } catch (error) {
        console.log(error);
        return dispatch({
            type: 'UPDATE_USER_UNSUCCESSFULL',
            payload: {
                errors: {},
                message: error.message,
            }
        })
    }
}

export default UserProvider;