import React, { useContext, useEffect, useState } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import SideNav from '../components/sideNav';
import Loading from '../components/loading';
import ChangePasswordComponent from '../components/changePasswordComponent';
import { UserDispatchContext, UserStateContext, getUser, updateUser, deleteUser, changeUserPassword } from '../contexts/user';
// import Header from '../components/header';

const Dashboard = () => {
  const dispatch = useContext(UserDispatchContext);
  const { loaded, user, message } = useContext(UserStateContext);
  const [toggler, setToggler] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  // console.log(user);

  useEffect(() => {
    (async () => await getUser(dispatch))()
  }, [toggler, dispatch]);

  useEffect(() => {
    if(message){
      alert(message);
    }
  }, [message]);

  const handleInput = e => {
    const { value } = e.target;
    setNewPassword(value);
  }

  const handleSubmit = async e => {
    // idReg1 = \\;
    e.preventDefault();
    if (newPassword && selectedUser) {
      console.log('yes');
      await changeUserPassword(dispatch, { userId: selectedUser._id, password: newPassword });
      // alert(message);
    } else {
      alert('Missing Paramters');
      console.log('no')
    }
    await getUser(dispatch);
    setChangePassword(false);
    // console.log(data);
  }

  const deleteCurrentUser = async user => {
    await deleteUser(dispatch, user);
    await getUser(dispatch);
  }

  const toggleUserAccess = async (data) => {
    await updateUser(dispatch, { ...data, enabled: !data.enabled });
    setToggler(!toggler);
  }

  const toggleUserType = async (data) => {
    await updateUser(dispatch, { ...data, admin: !data.admin });
    setToggler(!toggler);
  }

  if (!loaded) {
    return <Loading />
  }

  return (<>
    <Row style={{ minHeight: `100%` }} className=''>
      <Col lg={2} md={2} sm={12}>
        <SideNav />
      </Col>
      <Col lg={10} md={10} sm={12} className='pe-md-5 px-xl-5 text-start py-3'>
        {changePassword && <ChangePasswordComponent user={selectedUser} changePassword={changePassword} setChangePassword={setChangePassword} handleInput={handleInput} handleSubmit={handleSubmit} />}
        <h1>Registered Staff(s)</h1>
        <Table striped bordered hover responsive size="md" variant='dark'>
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Employee Email</th>
              <th>Employee ID</th>
              <th>Employee Access Grant</th>
              <th>Employee Access Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {user.map((person, index) => {
              return <tr key={index}>
                <td>{index + 1}</td>
                <td>{person.fullname}</td>
                <td>{person.email}</td>
                <td>{person.empId}</td>
                <td>{person.enabled ? 'enabled' : 'disabled'}</td>
                <td>{person.admin ? 'Admin' : 'User'}</td>
                <td>
                  <div className="d-flex justify-content-center">
                    <button className={`btn p-2 ${person.enabled ? 'btn-danger' : 'btn-success'}`} onClick={() => toggleUserAccess(person)}>{person.enabled ? 'Disable' : 'Enable'}</button>&nbsp;
                    <button className={`btn p-2 btn-info`} onClick={() => { setChangePassword(true); setSelectedUser(person) }}>Change User Password</button>&nbsp;
                    <button className={`btn p-2 ${person.admin ? 'btn-danger' : 'btn-success'}`} onClick={() => toggleUserType(person)}>{person.admin ? 'Set as User' : 'Set as Admin'}</button>&nbsp;
                    <button className={`btn p-2 btn-danger`} onClick={() => { deleteCurrentUser(person) }}>Delete User</button>
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  </>)
}

export default Dashboard;