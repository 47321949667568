import React from 'react';
import { Form, Button } from 'react-bootstrap';

const RegisterComponent = ({handleInput, handleSubmit, ...props}) => {
    return (
        <>
            <Form onSubmit={e => handleSubmit(e)} className='text-start d-grid gap-0 mb-3'>
                <Form.Group className="mb-3" controlId="formBasicName">
                    <label className='text-dark'>Full name</label>
                    <input type="text" name='fullname' placeholder="Surname Firstname" autoComplete='false' onChange={e => handleInput(e)} required autoFocus
                        className='w-100 p-2'
                        style={{
                            outline: 'none',
                            background: 'rgba(209, 209, 209, 0.1)',
                            border: 0,
                            borderBottom: '3px solid black'
                        }} />
                    {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <label className='text-dark'>Email(company email)</label>
                    <input type="email" name='email' placeholder="okiki.a@kadickintegrated.com" autoComplete='false' onChange={e => handleInput(e)} required className='w-100 p-2'
                        style={{
                            outline: 'none',
                            background: 'rgba(209, 209, 209, 0.1)',
                            border: 0,
                            borderBottom: '3px solid black'
                        }} />
                    {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <Form.Group className="mb-3" controlId="formBasicId">
                            <label className='text-dark'>Kadick ID</label>
                            <input type="text" name='empId' placeholder="Enter your employee ID" autoComplete='false' onChange={e => handleInput(e)} required
                                className='w-100 p-2'
                                style={{
                                    outline: 'none',
                                    background: 'rgba(209, 209, 209, 0.1)',
                                    border: 0,
                                    borderBottom: '3px solid black'
                                }} />
                            {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                        </Form.Group>
                    </div>
                    <div className="col">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <label className='text-dark'>Password</label>
                            <input type="password" name='password' onChange={e => handleInput(e)} required placeholder="Enter you password"
                                className='w-100 p-2'
                                style={{
                                    outline: 'none',
                                    background: 'rgba(209, 209, 209, 0.1)',
                                    border: 0,
                                    borderBottom: '3px solid black'
                                }} />
                        </Form.Group>
                    </div>
                </div>



                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group> */}
                <Button variant="" type="submit" style={{ backgroundColor: 'black', color: 'white' }}>
                    Register
                </Button>
            </Form>
        </>
    )
}

export default RegisterComponent;