import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
// import AlertMessage from '../components/alert';
import { idReg1 } from '../common/common';
import LoginComponent from '../components/loginComponent';
import { getAuth, AuthStateContext, AuthDispatchContext, login } from '../contexts/auth';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../components/loading';
import MyToast from '../components/toast';
import logo from '../assets/logo.png';
import kadick from '../assets/kadickImage1.png';


const Login = () => {
    const [data, setData] = useState({});
    const [show, setShow] = useState(false);
    const [err, setErr] = useState(null);
    let { loaded, isLoggedIn, errors, message: resMessage, color } = useContext(AuthStateContext);
    const Dispatch = useContext(AuthDispatchContext);

    useEffect(() => {
        getAuth(Dispatch);
    }, [Dispatch]);

    if (isLoggedIn) {
        // console.log('yes');
        return <Redirect to='/handbook' />;
    }
    // console.log(access);

    const newErr = errors != null ? errors.empId || errors.password: null;

    const handleInput = e => {
        const { value, name } = e.target;
        setData(data => {
            return {
                ...data,
                [name]: value,
            }
        });
    }

    const handleSubmit = async e => {
        // idReg1 = \\;
        if(idReg1.test(data.empId)){
        // if(data){
            console.log('yes');
            await login(Dispatch, data);
        } else {
            setErr('Employee ID is not valid, letters should be uppercase');
            console.log('no')
        }
        setShow(true);
        e.preventDefault();
        // console.log(data);
    }

    // if (loading) {
    //     return <Loading />
    // }

    return (
        <Container fluid style={{ color: 'white' }} className='py-5'>
            <div className="container shadow-lg p-md-0 rounded p-0">
            <div className="row rounded bg-danger position-relative p-0 g-0">
                    <div className="">
                        <img src={logo} className='position-absolute top-0 start-0 ps-3 pt-2' alt="kadick logo" />
                    </div>
                <div className="row rounded align-items-center bg-danger p-0 g-0">
                    <div className="col-md-6 order-2 rounded-end g-0">
                                <div className="w-75 mx-auto my-5 my-md-0">
                                <h1 className='text-dark text-start'>Kadick Account Login</h1>
                                {/* {resMessage && <AlertMessage variant={color} message={resMessage} />} */}
                                {(err || resMessage || errors) && <MyToast variant={color} show={show} setShow={setShow} message={newErr || resMessage || err} />}
                                {!loaded ? <Loading /> : <LoginComponent handleSubmit={handleSubmit} handleInput={handleInput} />}
                                <p className="">
                                    {`Don't have an account? `}
                                    <Link to='/register' className='text-decoration-none text-dark'>Register here.</Link>
                                </p>
                                </div>
                    </div>
                    <div className="col-md-6 rounded-md-start rounded-start" style={{backgroundImage: `url(${kadick})`, backgroundSize: 'auto', backgroundPosition: 'center', height: '75vh'}}>
                        {/* <img src={kadick} alt="kadick" className='img-fluid' /> */}
                    </div>
                </div>
            </div>
            </div>
        </Container>
    )
}

export default Login;