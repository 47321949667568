import React from 'react';

const Loading = (props) => {
    let size;
    if(props.full){
        size = {paddingTop: '45vh', height: '100vh'};
    }
    return (<div className="d-flex flex-column justify-content-center align-items-center" 
    style={size}
    >
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
                <p className="text-faded">If taking long, please check your internet</p>
        </div>)
}

export default Loading;