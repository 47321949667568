import React from 'react';
import {Toast} from 'react-bootstrap';


const MyToast = ({ message, setShow, show, stat, variant, ...props }) => {
    // const [show, setShow] = useState(props.show);

    // useEffect(()=>{
    //     setShow(true);
    // }, [])

    // if(!show){
    //     return <></>
    // }

    return (
      <div className='w-100'>
        {/* <Col xs={6}> */}
          <Toast className='mx-auto' onClose={() => setShow(false)} bg={variant ? variant.toLowerCase() : ''} show={show} delay={4000} autohide>
            <Toast.Header className={`bg-${variant}`}>
              <strong className="me-auto">Alert</strong>
            </Toast.Header>
            <Toast.Body className='text-dark'>{message}</Toast.Body>
          </Toast>
        {/* </Col> */}
      </div>
    )
}

export default MyToast;