import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './protected/protected';
import AuthProvider from './contexts/auth';
import UserProvider from './contexts/user';

import Login from './views/login';
import Register from './views/register';
import Handbook from './views/handbook';
import Dashboard from './views/dashboard';

function App() {
  return (
    <div className="App">
    <AuthProvider>
      <UserProvider>
        <Router>
          <Switch>
            <Route path='/' exact component={Login} />
            <Route path='/register' exact component={Register} />
            <ProtectedRoute path='/dashboard' exact component={Dashboard} />
            <ProtectedRoute path='/handbook' exact component={Handbook} />
          </Switch>
        </Router>
      </UserProvider>
    </AuthProvider>
  </div>
  );
}

export default App;
