import React, { useState, useEffect } from 'react';
import './modal.css';
import Loading from './loading';

const MyModal = ({show, ...props}) => {
  const [style, setStyle] = useState('my-modal');

  useEffect(()=>{
    if(show){
      setStyle('my-modal')
    } else {
      setStyle('my-modal-close');
      setTimeout(()=>setStyle(style=>style +' d-none'), 3000)
    }
  }, [show])

  return (
    <>
      {/* <Modal show={show} onHide={handleClose} style={{ padding: '0', paddingTop: '45vh', height: '100vh', textAlign: 'center' }}>
        <h3>Good day {props.user.fullname || 'User'}</h3>
        <p className='my-0'>Kindly be patient while the Handbook is loading...</p>
        <Loading />
        <p>Thank you</p>
      </Modal> */}
      <div className={style}>
        <div className="my-modal-content">
          <h3>Good day {props.user.fullname || 'User'}</h3>
          <p className='my-0'>Kindly be patient while the Handbook is loading...</p>
          <Loading />
          <p>Thank you</p>
        </div>
      </div>
    </>
  );
}

export default MyModal;