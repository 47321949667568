import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { getAuth, AuthStateContext, AuthDispatchContext, register } from '../contexts/auth';
import { Link, Redirect } from 'react-router-dom';
import { idReg1, firstnameReg, surnameReg, emailReg } from '../common/common';
import RegisterComponent from '../components/registerComponent';
import Loading from '../components/loading';
import MyToast from '../components/toast';
import kadick from '../assets/kadickImage1.png';
import logo from '../assets/logo.png';

const Register = () => {
    const [err, setErr] = useState(null);
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const { isLoggedIn, errors, color: resColor, message: resMessage, loaded: authLoaded } = useContext(AuthStateContext);
    const [color, setColor] = useState(resColor);
    const authDispatch = useContext(AuthDispatchContext);


    useEffect(() => {
        if (err) {
            setColor('danger');
        } else {
            setColor('success')
        }
    }, [err])

    useEffect(() => {
        getAuth(authDispatch);
    }, [authDispatch])

    if (isLoggedIn) {
        return <Redirect to='/handbook' />
    }

    const handleInput = e => {
        const { value, name } = e.target;
        setData(data => {
            return {
                ...data,
                [name]: value,
            }
        });
    }

    const handleSubmit = e => {
        if (!firstnameReg.test(data.fullname)) {
            setErr('Wrong fullname format!!')
        } else if (!surnameReg.test(data.fullname)) {
            setErr('Wrong fullname format!!')
        }
        else if (!emailReg.test(data.email)) {
            setErr('Wrong email format!!')
        }
        else if (!idReg1.test(data.empId)) {
            setErr('Wrong id format!!')
        }
        else {
            setErr(null)
            register(authDispatch, data);
        }
        setShow(true);
        e.preventDefault();
        console.log(data);
    }

    const newErr = errors != null ? (errors.empId || errors.fullname || errors.email) : null;


    return (
        <Container fluid style={{ color: '#bababa' }} className='py-5'>
            <div className="container shadow-lg p-md-0 rounded p-0">
                <div className="row rounded bg-danger position-relative p-0 g-0">
                    <div className="">
                        <img src={logo} className='position-absolute top-0 start-0 ps-3 pt-2' alt="kadick logo" />
                    </div>
                    <div className="row rounded align-items-center bg-danger p-0 g-0">
                        <div className="col-md-6 order-2 rounded-end">
                            <div className="mt-5 w-75 mx-auto mt-md-0">
                                <h1 className='text-dark text-start mb-3'>Kadick Employee Registration</h1>
                                {(err || newErr || resMessage) && <MyToast variant={color} message={err || newErr || resMessage} show={show} setShow={setShow} />}
                                {!authLoaded ? <Loading /> : <RegisterComponent handleInput={handleInput} handleSubmit={handleSubmit} />}
                                <p className="">
                                    {`Already have an account? `}
                                    <Link to='/' className='text-decoration-none text-dark'>Login here.</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 rounded-md-start rounded-start" style={{ backgroundImage: `url(${kadick})`, backgroundRepeat: 'no-repeat', backgroundSize: 'auto', backgroundPosition: 'center', height: '75vh' }}>
                            {/* <img src={kadick} alt="kadick" className='img-fluid' /> */}
                            {/* <div className="bg-success" style={{ height: '100%' }}></div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Register;