import React, { useEffect, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Loading from '../components/loading';
import { getAuth, AuthStateContext, AuthDispatchContext, logout } from '../contexts/auth';
import Header from '../components/header';
// import verify from '../common/verifyToken';

const ProtectedRoute = ({ component: Component, ...children }) => {
    const { loaded, user, isLoggedIn } = useContext(AuthStateContext);
    const Dispatch = useContext(AuthDispatchContext);

    const userLogout = () => {
        logout(Dispatch);
    }


    useEffect(() => {
        getAuth(Dispatch);
        // console.log('me');
    }, [Dispatch]);


    if (!loaded && !user) {
        return <Loading full={true} />
    }


    return <Route {...children} render={(props) => {
        // console.log(user);
        return (isLoggedIn ? <div style={{ minHeight: '100vh' }}>
            <Header user={user} logout={userLogout} />
            <div className="container-fluid p-0">
                <Component {...props} user={user} />
            </div>
        </div> : <Redirect to='/' />)
    }} />;
}

export default ProtectedRoute;