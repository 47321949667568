import React, { createContext, useReducer } from 'react';
import axios from 'axios';
import { api } from '../common/common';
// import useLocalStorage from '../common/useLocalStorage';

const initialState = {
    user: null,
    isLoggedIn: false,
    loading: false,
    loaded: false,
    errors: {},
    message: null,
    color: null
}

export const AuthStateContext = createContext();
export const AuthDispatchContext = createContext();

const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'REQUEST_LOGIN':
            return {
                ...state,
                loading: true,
                loaded: false,
                user: null,
                isLoggedIn: false,
            };
        case 'LOGIN_SUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: payload.user,
                errors: null,
                message: null,
                color: 'success',
                isLoggedIn: true,
            };
        case 'LOGIN_UNSUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: null,
                errors: payload.errors,
                message: payload.message,
                color: 'danger',
                isLoggedIn: false,
            };
        case 'REGISTER_SUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: payload.user,
                errors: payload.errors,
                message: payload.message,
                color: 'success',
                isLoggedIn: false,
            };
        case 'REGISTER_UNSUCCESSFULL':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: null,
                errors: payload.errors,
                message: payload.message,
                color: 'danger',
                isLoggedIn: false,
            };
        case 'LOGOUT':
            return {
                ...state,
                loading: false,
                loaded: true,
                user: null,
                isLoggedIn: false,
                errors: null,
                message: null,
                color: null,
            };

        default:
            throw new Error('Unknown action type');
    }
}

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, initialState);
    return (
        <AuthDispatchContext.Provider value={dispatch}>
            <AuthStateContext.Provider value={state}>
                {children}
            </AuthStateContext.Provider>
        </AuthDispatchContext.Provider>
    )
}


export const getAuth = async (dispatch) => {
    // console.log(token);
    let token = JSON.parse(localStorage.getItem('kadick'));
    dispatch({ type: 'REQUEST_LOGIN' });
    if(token){
        await axios.get(`${api}users/verify`, { headers: { 'auth_token': token } })
        .then(res => {
            if (res.data.message === 'success') {
                return dispatch({
                    type: 'LOGIN_SUCCESSFULL',
                    payload: {
                        user: res.data.user
                    }
                });
            }
            else return dispatch({
                type: 'LOGOUT'
            });
        })
        .catch(err => {
            console.log(err.message);
            return dispatch({
                type: 'LOGOUT'
            });
        });
    } else {
        dispatch({
            type: 'LOGOUT'
        });
    }
}

export const logout = async (dispatch) => {
    dispatch({ type: 'REQUEST_LOGIN' });
    // console.log('i did');
    localStorage.setItem('kadick', JSON.stringify(null));
    dispatch({ type: 'LOGOUT' });
}

export const register = async (dispatch, data) => {
    // dispatch({ type: 'REQUEST_USER' });
    console.log(data);
    try {
        await axios.post(`${api}users/register`, data)
    .then(res => {
            console.log(res.data);
            if (!res.data.success) {
                console.log(res.data.message);
                return dispatch({
                    type: 'REGISTER_UNSUCCESSFULL',
                    payload: {
                        errors: res.data.errors,
                        message: res.data.message,
                    }
                })
            }
            console.log(res.data.message);

            return dispatch({
                type: 'REGISTER_SUCCESSFULL',
                payload: {
                    user: res.data.user,
                    errors: res.data.errors,
                    message: res.data.message,
                }
            })
        })
        .catch(err => {
            if (!err.response) {
                console.log(err.message);

                return dispatch({
                    type: 'REGISTER_UNSUCCESSFULL',
                    payload: {
                        errors: {},
                        message: err.message,
                    }
                })
            }
            else {
                console.log(err.response.data.message);

                return dispatch({
                    type: 'REGISTER_UNSUCCESSFULL',
                    payload: {
                        errors: err.response.data.errors,
                        message: err.response.data.message,
                    }
                })
            };
        })
    } catch (error) {
        console.log(error);
        return dispatch({
            type: 'REGISTER_UNSUCCESSFULL',
            payload: {
                errors: {},
                message: error.message,
            }
        })
    }
}

export const login = async (dispatch, data) => {
    dispatch({ type: 'REQUEST_LOGIN' });
    await axios.post(`${api}users/login`, data)
        .then(res => {
            if (!res.data.success) {
                // console.log(res.data.message);
                return dispatch({
                    type: 'LOGIN_UNSUCCESSFULL',
                    payload: {
                        errors: res.data.errors,
                        message: res.data.message,
                    }
                })
            }
            localStorage.setItem('kadick', JSON.stringify(res.data.auth_token));
            // setStorage('kadick', res.data.auth_token);
            // setTimeout(()=>{
            return dispatch({
                type: 'LOGIN_SUCCESSFULL',
                payload: {
                    user: res.data.user,
                    errors: res.data.errors,
                    message: res.data.message,
                }
            })
            // }, 1000);
            // return 0;
        })
        .catch(err => {
            if (!err.response) {
                return dispatch({
                    type: 'LOGIN_UNSUCCESSFULL',
                    payload: {
                        errors: {},
                        message: err.message,
                    }
                })
            }
            else {
                return dispatch({
                    type: 'LOGIN_UNSUCCESSFULL',
                    payload: {
                        errors: err.response.data.errors,
                        message: err.response.data.message,
                    }
                })
            };
        })
}

export default AuthProvider;