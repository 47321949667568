import React from 'react';
import { Form, Button, Modal } from 'react-bootstrap';

const ChangePasswordComponent = ({ changePassword, setChangePassword, handleInput, handleSubmit, user, ...props }) => {

    return (
        <>
            <Modal show={changePassword} style={{ padding: '0', paddingTop: '20vh', height: '100vh', textAlign: 'center' }}>
                <Form onSubmit={e => handleSubmit(e)} className='text-start d-grid gap-0 mb-3 p-5'>
                    <h2>Change {user.fullname || 'User'} Password</h2>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <label htmlFor='tem' className='d-block mb-2 text-dark'>Password</label>
                        <input type="text" name='password' className='w-100 p-2' onChange={e => handleInput(e)} required placeholder="Enter new user password"
                            style={{
                                background: 'rgba(209, 209, 209, 0.1)',
                                border: 0,
                                outline: 'none',
                                borderBottom: '3px solid black'
                            }} />
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                    <Button variant="" type="submit" style={{ backgroundColor: 'black', color: 'white' }}>
                        Change User Password
                    </Button>
                    <Button variant="" type="btn" onClick={()=>setChangePassword(false)} style={{ backgroundColor: 'red', color: 'white' }}>
                        Cancel
                    </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default ChangePasswordComponent;