import React, { useState, useEffect } from 'react';
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
// import { PDFReader, MobilePDFReader } from 'reactjs-pdf-reader';
import book from '../assets/Kadick_EHB_August_2021.pdf';
import MyModal from '../components/modal';
// import AuthProvider from '../contexts/auth';
// import Loading from '../components/loading';

const Handbook = (props) => {
    const [show, setShow] = useState(true);

    useEffect(()=>{
        if(props.user){
            setTimeout(()=>setShow(false), 2000);
        }
    }, [props.user]);


    const Handbook = <object data={book} type="application/pdf" style={{ width: '98vw', height: '100vh', margin: '0 auto'}}>
    <div className="container-fluid mt-5 text-wrap" style={{minHeight: '90vh', width: '100vw', verflow:'scroll'}}>
    <p className="lead mt-3 pt-3">We are sorry we can't display the Kadick Employee Handbook for you on a mobile device. <br />
        However, you can click the download button to get it or  view button to open on a new tab.<br /> <span className=''> <i><b>Kindly note that download starts automatically when you click on the button so please check your downloads to confirm it has started.</b></i></span> </p>
        <a href="https://drive.google.com/file/d/1IZPYgnVviit-wTOoZUlgiuXqIji1zLQ1/view?usp=sharing" target="_blank" rel="/ noreferrer" className='btn btn-success btn-lg shadow-lg mt-4'>View Handbook</a>
        <p className="lead mt-2 pt-2">Thanks and happy reading</p>
        <p style={{fontSize: '40px'}}>&#128526;</p>
        <a href={book} className='btn btn-danger btn-lg shadow-lg mt-4' download="Kadick Employee Handbook">Download Handbook</a>
    </div>
    </object>
    // const newHandbook = <iframe src={`http://docs.google.com/viewer?url=https://docs.google.com/document/d/1Sc9DBAJzUlqgLz95ZaW7c6dLoM6ebdTQBwwbikS7xas&embedded=true`} style={{ width: '98vw', height: '100vh', margin: '0 auto', textAlign: "center"}} frameBorder="0"></iframe>
    // const newHandbook = <iframe src={`http://drive.google.com/drive/folders/1Hr3eZTZKgotY4tgVBG7s0Um4V3hEe9uD`} style={{ width: '98vw', height: '100vh', margin: '0 auto', textAlign: "center"}} frameBorder="0"></iframe>

    return (
        <div className="container-fluid p-0">
            {true && <MyModal user={props.user} show={show} />}
            <div className='d-flex justify-content-center mt-5'>
                {/* {newHandbook} */}
                {Handbook}
            </div>
            <div className="p-0 bg-success">
            </div>
        </div>
    )
}

export default Handbook;