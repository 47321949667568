import React from 'react';
import { Form, Button } from 'react-bootstrap';

const LoginComponent = ({handleInput, handleSubmit, ...props}) => {

    return (
        <>
            <Form onSubmit={e => handleSubmit(e)} className='text-start d-grid gap-0 mb-3'>
                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Kadick ID</Form.Label>
                    <Form.Control type="text" name='empId' class='input' placeholder="Enter your employee ID" autoComplete='false' onChange={e => handleInput(e)} autoFocus /> */}
                    {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                {/* </Form.Group> */}

                <Form.Group className='mb-3'>
                    <label htmlFor='tem' className='d-block mb-2 text-dark'>Kadick ID</label>
                    <input type="text" id='tem' name='empId' autoFocus onChange={e => handleInput(e)} required placeholder='KE123 or TDR123' autoComplete='off' 
                     className='w-100 p-2'
                    style={{
                        outline: 'none',
                        background: 'rgba(209, 209, 209, 0.1)',
                        border: 0,
                        borderBottom: '3px solid black'
                    }} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <label htmlFor='tem' className='d-block mb-2 text-dark'>Password</label>
                    <input type="password" name='password' className='w-100 p-2' onChange={e => handleInput(e)} required placeholder="Enter you password" 
                    style={{
                        background: 'rgba(209, 209, 209, 0.1)',
                        border: 0,
                        outline: 'none',
                        borderBottom: '3px solid black'
                    }} />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group> */}
                <Button variant="" type="submit" style={{ backgroundColor: 'black', color: 'white' }}>
                    Login
                </Button>
            </Form>
        </>
    )
}

export default LoginComponent;