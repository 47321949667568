import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

const SideNav = (props) => {
    return (
        <Navbar bg="light" className="flex-md-column">
            <Navbar.Brand href="#"><h2>Dashboard</h2></Navbar.Brand>
            <Nav defaultActiveKey="/home" className="flex-md-column">
                <Nav.Link href="/handbook">handbook</Nav.Link>
                <Nav.Link eventKey="link-1">Link</Nav.Link>
                <Nav.Link eventKey="link-2">Link</Nav.Link>
                <Nav.Link eventKey="disabled" disabled>
                    Disabled
                </Nav.Link>
            </Nav>
        </Navbar>
    )
}

export default SideNav;